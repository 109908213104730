import React, { useReducer } from "react";

const DQIContext = React.createContext([{}, () => {}]);
function dataReducer(state, action) {
    const {payload} = action
  switch (action.type) {
    case "Capture_Data":
        return {...state, QueryID:payload.QueryID,QueryName:payload.QueryName,  Owner:payload.Owner,Source:payload.Source}
    case "SnapshotID":
      return {...state, SnapshotID: payload.SnapshotID}
    default:
      return state;
  }
}

const DQIContextProvider = (props) => {
    // const [QueryID, setID] = useState("")
  const initState = {
    QueryID: "",
    QueryName:""
  };

  const [data, dispatch] = useReducer(dataReducer, initState);


    return (
    <DQIContext.Provider value={[data, dispatch]}>
      {props.children}
    </DQIContext.Provider>)
  
};

export { DQIContext, DQIContextProvider };
