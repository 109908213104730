import React from "react";
import { CssBaseline } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { blueGrey } from "@material-ui/core/colors";
import NotoSans from "../fonts/NotoSans-Regular.ttf";

const notoSans = {
  fontFamily: "NotoSans",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
  local('NotoSans'),
  local('NotoSans-Regular'),
  url(${NotoSans}) format('ttf')`,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#00164e",
    },
    secondary: {
      main: blueGrey[500],
    },
    info: {
      main: "#2196f3",
    },
  },
  typography: {
    fontFamily: "NotoSans, sans-serif",
  },
  button: {
    backgroundColor: "rgb(0, 43, 153)",
    color: "rgb(255, 255, 255)",
    borderRadius: "4px",
    border: "2px solid rgb(0, 43, 153)",
    height: "36px",
    width: "176px",
    fontFamily: "NotoSans",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [notoSans],
      },
    },
  },
  background: {
    backgroundColor: "#F0F1F4",
  },
});

export default function ({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
