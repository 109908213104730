import React, { useState } from 'react';

const AuthContext = React.createContext([{}, () => {}]);

const AuthContextProvider = (props) => {
    const [auth, setAuth] = useState([]);
    
    return (
        <AuthContext.Provider value={[auth, setAuth]}>
            {props.children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthContextProvider };