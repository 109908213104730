import React, { useReducer } from "react";

const AccountManagementContext = React.createContext([{}, () => {}]);
function dataReducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_SAP_ACCOUNT_DATA":
      return { ...state, SapAccounts: payload.SapAccounts };
    case "SET_SAP_ACCOUNT":
        return { ...state, SapAccount: payload.SapAccount };
    default:
      return state;
  }
}

const AccountManagementContextProvider = (props) => {
  // const [QueryID, setID] = useState("")
  const initState = {
    SapAccounts: [],
    SapAccount:{}
  };

  const [data, dispatch] = useReducer(dataReducer, initState);
  return (
    <AccountManagementContext.Provider value={[data, dispatch]}>
      {props.children}
    </AccountManagementContext.Provider>
  );
};

export { AccountManagementContext, AccountManagementContextProvider };
