import React, { useReducer } from "react";

const SapToEdpReconContext = React.createContext([{}, () => {}]);
function dataReducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "SET_QUERY_RESULTS_LIFESTYLE":
      return {
        ...state,
        DataLifestyle: payload.Data,
      };
    case "SET_QUERY_RESULTS_GIP":
      return {
        ...state,
        DataGIP: payload.Data,
      };
    case "SET_QUERY_RESULTS_STASH":
      return {
        ...state,
        DataStash: payload.Data,
      };
    case "SET_QUERY_RESULTS_MOBIILFE":
      return {
        ...state,
        DataMobilife: payload.Data,
      };
    case "SET_DATE":
      return { ...state, Date: payload.Date };
    case "SET_SOURCE_SYSTEM":
      return { ...state, SourceSystem: payload.SourceSystem };
    case "SHOW_RESULTS":
      return { ...state, ShowResults: payload.ShowResults };
    case "SET_ACCOUNTS_IN_BALANCE_LIFESTYLE":
      return {
        ...state,
        AccountsInBalanceLifeStyle: payload.AccountsInBalance,
        AccountsNotInBalanceLifeStyle: payload.AccountsNotInBalance,
      };
    case "SET_ACCOUNTS_IN_BALANCE_GIP":
      return {
        ...state,
        AccountsInBalanceGIP: payload.AccountsInBalance,
        AccountsNotInBalanceGIP: payload.AccountsNotInBalance,
      };

    case "SET_ACCOUNTS_IN_BALANCE_STASH":
      return {
        ...state,
        AccountsInBalanceStash: payload.AccountsInBalance,
        AccountsNotInBalanceStash: payload.AccountsNotInBalance,
      };
    case "SET_ACCOUNTS_IN_BALANCE_MOBILIFE":
      return {
        ...state,
        AccountsInBalanceMobilife: payload.AccountsInBalance,
        AccountsNotInBalanceMobilife: payload.AccountsNotInBalance,
      };
    default:
      return state;
  }
}

const SapToEdpReconContextProvider = (props) => {
  // const [QueryID, setID] = useState("")
  const initState = {
    DataLifestyle: [],
    DataGIP: [],
    DataStash: [],
    DataMobilife: [],
    SourceSystem: "",
    Date: new Date(),
    ShowResults: false,
    AccountsInBalanceLifeStyle: 0,
    AccountsNotInBalanceLifeStyle: 0,
    AccountsInBalanceGIP: 0,
    AccountsNotInBalanceGIP: 0,
    AccountsInBalanceStash: 0,
    AccountsNotInBalanceStash: 0,
    AccountsInBalanceMobilife: 0,
    AccountsNotInBalanceMobilife: 0,
  };

  const [data, dispatch] = useReducer(dataReducer, initState);
  return (
    <SapToEdpReconContext.Provider value={[data, dispatch]}>
      {props.children}
    </SapToEdpReconContext.Provider>
  );
};

export { SapToEdpReconContext, SapToEdpReconContextProvider };
