import React, { useReducer } from "react";

const DVDContext = React.createContext([{}, () => {}]);
function dataReducer(state, action) {
  const { payload } = action;
  switch (action.type) {
    case "ADD_SELECTED":
      return {
        ...state,
        SelectedTemplates: payload.SelectedTemplates,
        SelectedSystems: payload.SelectedSystems,
      };

    case "ADD_SELECTED_CROSS":
      return {
        ...state,
        SelectedTemplate: payload.SelectedTemplate,
        SelectedCompareTemplate: payload.SelectedCompareTemplate,
        SeelectedColumn: payload.SeelectedColumn,
        SelectedSourceSystem: payload.SelectedSourceSystem,
      };
    case "SHOW_RESULTS":
      return { ...state, ShowResults: payload.ShowResults };
    case "SHOW_CROSS_RESULTS":
      return { ...state, ShowCrossResults: payload.ShowCrossResults };
    case "ADD_QUERY_RESULTS":
      return { ...state, QueryResults: payload };
    case "ADD_CROSS_QUERY_RESULTS":
      return { ...state, CrossQueryResults: payload };
    default:
      return state;
  }
}

const DVDContextProvider = (props) => {
  // const [QueryID, setID] = useState("")
  const initState = {
    SelectedTemplates: [],
    SelectedTemplate: "",
    SelectedSystems: [],
    SelectedCompareTemplate: "",
    SeelectedColumn: "",
    SelectedSourceSystem: "",
    QueryResults: [],
    CrossQueryResults: [],
    ShowResults: false,
    ShowCrossResults: false,
  };

  const [data, dispatch] = useReducer(dataReducer, initState);
  return (
    <DVDContext.Provider value={[data, dispatch]}>
      {props.children}
    </DVDContext.Provider>
  );
};

export { DVDContext, DVDContextProvider };
