import React, { useState } from 'react';

const ApprovalContext = React.createContext([{}, () => {}]);

const ApprovalContextProvider = (props) => {
    const [state, setState] = useState({});
    
    return (
        <ApprovalContext.Provider value={[state, setState]}>
            {props.children}
        </ApprovalContext.Provider>
    );
}

export { ApprovalContext, ApprovalContextProvider };

