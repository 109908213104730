import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Theme from "./theme/Theme";
import * as serviceWorker from "./serviceWorker";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import { AppContextProvider } from "./context/AppContext";

import { ApprovalContextProvider } from "./context/ApprovalContext";
import { DQIContextProvider } from "./context/DQIContext";
import { DVDContextProvider } from "./context/DVDContext";
import { SapToEdpReconContextProvider } from "./context/SapToEdpReconContext";
import { AccountManagementContextProvider } from "./context/AccountManagementContext";
import { AuthContextProvider } from "./context/AuthContext";
import MuiTheme from "./theme/muiTheme";
if (process.env.NODE_ENV === "production") {
  console.log(process.env.NODE_ENV);
  disableReactDevTools();
}

ReactDOM.render(
  <Theme>
    <MuiTheme>
      <AuthContextProvider>
        <AppContextProvider>
          <ApprovalContextProvider>
            <DQIContextProvider>
              <DVDContextProvider>
                <SapToEdpReconContextProvider>
                  <AccountManagementContextProvider>
                    <App />
                  </AccountManagementContextProvider>
                </SapToEdpReconContextProvider>
              </DVDContextProvider>
            </DQIContextProvider>
          </ApprovalContextProvider>
        </AppContextProvider>
      </AuthContextProvider>
    </MuiTheme>
  </Theme>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
