import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { PUBLIC_URL } from "../config";

function getURI() {
  console.log(process.env)
  if (process.env.NODE_ENV === "production") {
    return PUBLIC_URL;
  } else return "http://localhost:3000/";

  
}
// Msal Configurations
const msalConfig = {

  auth: {
    clientId: "d946a237-1962-4a7c-8dc1-7b444dd99adf",
    authority: "https://login.microsoftonline.com/liberty.co.za",
    redirectUri: `${getURI()}dashboard`,
    postLogoutRedirectUri: `${getURI()}`,
    validateAuthority: false,
  },
  cache: {
    // Cache can be set using cacheLocation property. Default is session Storage
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    secureCookies:true,
  },
};
// Authentication Parameters
const authenticationParameters = {
  scopes: ["openid", "profile", "User.Read", "directory.read.all"],
};

// Options
const options = {
  loginType: LoginType.Redirect,
};

export const authProvider = new MsalAuthProvider(
  msalConfig,
  authenticationParameters,
  options
);
