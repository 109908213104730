import React, { useState } from 'react';

const AppContext = React.createContext([{}, () => {}]);

const AppContextProvider = (props) => {
    const [auth, setAuth] = useState({});
    
    return (
        <AppContext.Provider value={[auth, setAuth]}>
            {props.children}
        </AppContext.Provider>
    );
}

export { AppContext, AppContextProvider };

