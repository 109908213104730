import React, { Suspense, lazy, useEffect } from "react";
import Spinner from "./components/Spinner/Spinner";
import { AzureAD, AuthenticationState } from "react-aad-msal";
import { authProvider } from "./_helpers/AuthProvider";

const AuthenticatedApp = lazy(() => import("./apps/AuthenticatedApp"));
const UnAuthenticatedApp = lazy(() => import("./apps/UnAuthenticatedApp"));

function App() {
  useEffect(() => {
    var isEdge = window.navigator.userAgent.toLocaleLowerCase().indexOf("edg")>=0
    if (isEdge) document.body.style.zoom = "95%";
  },[])
  return (
    <Suspense fallback={<Spinner />}>
      <AzureAD provider={authProvider} forceLogin={true}>
        {({ authenticationState }) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              return <AuthenticatedApp />;
            case AuthenticationState.Unauthenticated:
              return <UnAuthenticatedApp />;
            case AuthenticationState.InProgress:
              return <Spinner />;
            default:
              return <Spinner />;
          }
        }}
      </AzureAD>
    </Suspense>
  );
}
export default App;
